export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },

  // encryptionkey:"ohhprotechcrednt",
  // baseUrl1: 'http://dev01.ohhpro.com:9080/ohhpro_admin/admin/',
  // baseUrl: 'http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/',
  // baseUrl2: 'http://dev01.ohhpro.com:9080/ohhpro/rest/',
  // loginUrl: 'http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/logIn/web/Admin',
  // imageUrl: 'http://dev01.ohhpro.com:9080/uploads/user_pics/',
  // appImageUrl: 'http://dev01.ohhpro.com:9080/uploads/app_src/',
  // docsImg: 'http://dev01.ohhpro.com:9080/uploads/user_docs/',
  // mallGraphUrl:'http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/mall/manager/',
  // houseDueUrl :'http://dev01.ohhpro.com:9080/ohhpro/rest/apart/pay/',
  // cashUrl: "http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/razorPay/maintenance/cash/maintenanceAmount",
  // houseDueUrl1 :"http://dev01.ohhpro.com:9080/ohhpro_admin/admin/",
  // imageDirectoryPath: 'http://dev01.ohhpro.com:9080/uploads/noticeBoard/',
  // assetUrl:'http://dev01.ohhpro.com:9080/ohhpro/rest/',
  // base:'http://dev01.ohhpro.com:9080/',
  // emergency: 'http://dev01.ohhpro.com:9080/ohhpro/rest/',
  // razorpayUrl:'http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/razorPay/maintenance/',
  // ohhproAsset:'http://dev01.ohhpro.com:9080/ohhpro/rest/assetNew/',
  // newChanges:'http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/financial/',
  // razorpayUrl2:'http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/assetnew/',
  // assetPic:'http://dev01.ohhpro.com:9080/uploads/fina_anly/app_asset/photo/',
  // assetInvoicePic: 'http://dev01.ohhpro.com:9080/uploads/fina_anly/app_asset/invoice_pic/',
  // assetVendorReceipt: 'http://dev01.ohhpro.com:9080/uploads/fina_anly/vendor_receipt/',
  // assetLedger: 'http://dev01.ohhpro.com:9080/uploads/fina_anly/ledger_invoice/',
  // inventoryInvoice:'http://dev01.ohhpro.com:9080/uploads/fina_anly/inventory_invoice/',
  // ledgerInvoice:'http://dev01.ohhpro.com:9080/uploads/fina_anly/ledger_invoice/',
  // addtemplate:'http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/',
  // performance:'http://dev01.ohhpro.com:9080/ohhpro/rest/maintenanceTracker/',
  // duplicate:'http://dev01.ohhpro.com:9080/ohhpro/rest/user/',
  // PopUpTextDelete: 'Are You Sure To Delete ?',
  // PopUpTextDeActivate: 'Are You Sure To De-Activate ?',
  // basefilepath:'/usr/local/tomcat9_dev/webapps/uploads/user_pics/',
  // regAttendance: 'http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/regularvisitorattendance/',
  // chatapi:'http://dev01.ohhpro.com:9080/ohhpro_Junction/',
  // websocket_connect_url:'http://dev01.ohhpro.com:9080/ohhpro_Junction/queriesandcomplainchat',
  // qrDownload:'http://dev01.ohhpro.com:9080/uploads/qr_codes/',
  // imgappartment:'http://dev01.ohhpro.com:9080/uploads/appimage/',
  // app_id: 1001,
  // bulk_upload:'http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/razorPay/maintenancenew/'

  /*Production Build */
  encryptionkey:"ohhprotechcrednt",
  assetImageURL:'https://ohhpro.com:8443/uploads/fina_anly/app_asset/photo/',
  baseUrl1: 'https://ohhpro.com:8443/ohhpro_admin/admin/',
  baseUrl: 'https://ohhpro.com:8443/ohhpro_Junction/rest/',
  baseUrl2: 'https://ohhpro.com:8443/ohhpro/rest/',
  loginUrl: 'https://ohhpro.com:8443/ohhpro_Junction/rest/logIn/web/Admin',
  imageUrl: 'https://ohhpro.com:8443/uploads/user_pics/',
  appImageUrl: 'https://ohhpro.com:8443/uploads/app_src/',
  docsImg: 'https://ohhpro.com:8443/uploads/user_docs/',
  mallGraphUrl:'https://ohhpro.com:8443/ohhpro_Junction/rest/mall/manager/',
  houseDueUrl :'https://ohhpro.com:8443/ohhpro/rest/apart/pay/',
  houseDueUrl1 :"https://ohhpro.com:8443/ohhpro_admin/admin/",
  cashUrl: "https://ohhpro.com:8443/ohhpro_razorpay/payment/razorPay/maintenance/cash/maintenanceAmount",
  imageDirectoryPath:'https://ohhpro.com:8443/uploads/noticeBoard/',
  assetUrl:'https://ohhpro.com:8443/ohhpro/rest/',
  base: 'https://ohhpro.com:8443/',
  emergency: 'https://ohhpro.com:8443/ohhpro/rest/',
  razorpayUrl:'https://ohhpro.com:8443/ohhpro_razorpay/payment/razorPay/maintenance/',
  ohhproAsset:'https://ohhpro.com:8443/ohhpro/rest/assetNew/',
  newChanges:'https://ohhpro.com:8443/ohhpro_razorpay/payment/financial/',
  razorpayUrl2:'https://ohhpro.com:8443/ohhpro_razorpay/payment/assetnew/',
  assetPic:'https://ohhpro.com:8443/uploads/fina_anly/app_asset/photo/',
  assetInvoicePic: 'https://ohhpro.com:8443/uploads/fina_anly/app_asset/invoice_pic/',
  assetVendorReceipt: 'https://ohhpro.com:8443/uploads/fina_anly/vendor_receipt/',
  assetLedger: 'https://ohhpro.com:8443/uploads/fina_anly/ledger_invoice/',
  inventoryInvoice:'https://ohhpro.com:8443/uploads/fina_anly/inventory_invoice/',
  ledgerInvoice:'https://ohhpro.com:8443/uploads/fina_anly/ledger_invoice/',
  addtemplate:'https://ohhpro.com:8443/ohhpro_razorpay/payment/',
  PopUpTextDelete: 'Are You Sure To Delete ?',
  PopUpTextDeActivate: 'Are You Sure To De-Activate ?',
  basefilepath:'/usr/local/tomcat9_prod/webapps/uploads/user_pics/',
  regAttendance: 'https://ohhpro.com:8443/ohhpro_Junction/rest/regularvisitorattendance/',
  chatapi:'https://ohhpro.com:8443/ohhpro_Junction/',
  performance:'https://ohhpro.com:8443/ohhpro/rest/maintenanceTracker/',
  duplicate:'https://ohhpro.com:8443/ohhpro/rest/user/',
 websocket_connect_url:'https://ohhpro.com:8443/ohhpro_Junction/queriesandcomplainchat',
  qrDownload:'https://ohhpro.com:8443/uploads/qr_codes/',
  imgappartment:'https://ohhpro.com:8443/uploads/appimage/',
  bulk_upload:'https://ohhpro.com:8443/ohhpro_razorpay/payment/razorPay/maintenancenew/'
};
