import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';
import { environment } from 'src/environments/environment.prod';
import { User } from '../models/auth.models';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    user: User;
    constructor(private http: HttpClient) {
        
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

     /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        var dart = {
            email: email,
            password: password
        }
        return this.http.post<any>(environment.baseUrl+"logIn/web/Admin",dart);
    }

      sendOtp(data:any){
        return this.http.post<any>(environment.baseUrl+"logIn/web/AdminNew",data);
      }
      
      getUserDetails(data:any){
        return this.http.post<any>(environment.baseUrl+"logIn/getAdminData",data);
      }
    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }
}

