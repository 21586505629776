<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-7">
                Connect With Us <a href="https://twitter.com/OhhproT" target="_blank"><i class='fab fa-fw fa-twitter'></i></a>&nbsp;&nbsp;<a href="https://www.facebook.com/Ohhpro6/?modal=admin_todo_tour" target="_blank"><i class="fab fa-fw fa-facebook"></i></a>&nbsp;&nbsp;<a href="https://www.instagram.com/ohhpro_junction/?utm_medium=copy_link" target="_blank"><i class="fab fa-fw fa-instagram"></i></a> &nbsp;&nbsp;&nbsp; Get it On Google Play Store <a href="https://play.google.com/store/apps/details?id=com.ohhpro.ohhproservice&hl=en&gl=US" target="_blank"><i class='fab fa-google-play'></i></a> and Apple App Store <a href="https://apps.apple.com/za/app/ohhpro-junction/id1596785670" target="_blank"><i class='fab fa-app-store'></i></a>
            </div>
            <div class="col-sm-5">
                <div class="text-sm-end d-none d-sm-block">
                    {{year}} © Copyright Ohhpro Technologies Pvt Ltd. All Rights Reserved
                </div>
            </div>
        </div>
    </div>
</footer>