import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/dashboard',
        badge: {
            variant: 'success',
            text: 'NEW',
        },
    },
    {
        id: 7,
        isLayout: true
    },

    {
        id: 9,
        label: 'Apartment Setup',
        icon: 'bx-buildings',
        subItems: [
            {
                id: 1,
                label: 'Block',
                link: '/block',
                parentId: 9
            },
            {
                id: 2,
                label: 'Floor',
                link: '/floor',
                parentId: 9
            },
            {
                id: 3,
                label: 'House',
                link: '/house',
                parentId: 9
            },
            {
                id: 4,
                label: 'Lift',
                link: '/lift',
                parentId: 9
            },
            {
                id: 5,
                label: 'Parking',
                link: '/parking',
                parentId: 9
            }

        ]
    },
    {
        id: 3,
        label: 'Complain Management',
        icon: 'bx-user-voice',
        link:'/complain'
    },
    {
        id: 13,
        label: 'Daily Help',
        icon: 'bx-buildings',
        subItems: [
            {
                id: 1,
                label: 'Facility Manager',
                link: '/facility-manager',
                parentId: 13
            },
            {
                id: 2,
                label: 'Regular Visitor',
                link: '/regular-visitor',
                parentId: 13
            },
            {
                id: 3,
                label: 'Signaller',
                link: '/signaller',
                parentId: 13
            },
            {
                id: 4,
                label: 'Street Vendor',
                link: '/street-vendor',
                parentId: 13
            },
            {
                id: 5,
                label: 'RV Attendance',
                link: '/rv-attendance',
                parentId: 13
            }

        ]
    },
    {
        id: 14,
        label: 'Financial Transaction',
        isTitle: true
    },
    {
        id: 10,
        label: 'Asset & Amenity',
        icon: 'bx-task',
        link:'/asset',
        badge: {
            variant: 'success',
            text: 'NEW',
        },
    },
    {
        id: 11,
        label: 'Apartment Bank Account',
        icon: 'bxs-bank',
        link: '/bank',

    },
    {
        id: 12,
        label: 'General Ledger',
        icon: 'bx-detail',
        link:'/ledger'
    },
    {
        id: 21,
        label: 'Inventory',
        icon: 'bxs-report',
        link:'/inventory'
    },
    {
        id: 29,
        label: 'Maintenance Payment',
        icon: 'bx-rupee',
        link:'/maintenance'

    },
    {
        id: 36,
        label: 'Assorted Invoice',
        icon: 'bx-receipt',
        link:'/assorted',
        badge: {
            variant: 'success',
            text: 'NEW',
        },
    },
    {
        id: 39,
        label: 'Trail Balance',
        icon: 'bxs-book',
        link:'/trail-balance'

    },
    {
        id: 44,
        label: 'Tax Details',
        icon: 'bx-money',
        link:'/tax'

    },
    {
        id: 48,
        label: 'Apartment Vendor',
        icon: 'bx-store',
        link:'/vendor'
    },
    {
        id: 49,
        label: 'Complete Invoice',
        icon: 'bx-receipt',
        link:'/complete-invoice',
        badge: {
            variant: 'success',
            text: 'NEW',
        },
    },
    {
        id: 80,
        label: 'Residents Details',
        isTitle: true
    },
    {
        id: 81,
        label: 'Community Info',
        icon: 'bx-walk',
        subItems: [
            {
                id: 82,
                label: 'Admin',
                link: '/admin',
                parentId: 81
            },
            {
                id: 83,
                label: 'Approval Pending',
                link: '/approval-pending',
                parentId: 81
            },
            {
                id: 84,
                label: 'Management Committee',
                link: '/management-committee',
                parentId: 81
            },
            {
                id: 85,
                label: 'Non-Registred User',
                link: '/non-registred-user',
                parentId: 81
            },
            {
                id: 86,
                label: 'Owner Approval',
                link: '/owner-approval',
                parentId: 81
            },
            {
                id: 87,
                label: 'User Details',
                link: 'user-details',
                parentId: 81
            },
            // {
            //     id: 88,
            //     label: 'Residentials Info',
            //     link: '/residentials-info',
            //     parentId: 81
            // },

        ]
    },
    {
        id: 41,
        label: 'Patrolling Information',
        isTitle: true
    },
    {
        id: 42,
        label: 'Patrolling Setup',
        icon: 'bxs-shield-alt-2',
        link:'/patrolling-setup'
    },
    {
        id: 56,
        label: 'Other Features',
        isTitle: true
    },
    {
        id: 57,
        label: 'Notice Board',
        icon: 'bxs-bell-ring',
        link:'/notice'
    },
    {
        id: 73,
        label: 'Phone Book',
        icon: 'bxs-book-content',
        link:'helpdesk-contact'
    },
    {
        id: 73,
        label: 'Polling & Voting',
        icon: 'bx-archive-in',
        link:'/polling'
    },
    {
        id: 73,
        label: 'Service Request',
        icon: 'bx-loader-circle',
        link:'/service-request'
    },
    {
        id: 73,
        label: 'Today Visitor',
        icon: 'bx-walk',
        link:'/today-visitor'
    },
];

